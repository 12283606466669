<template>
  <b-container fluid>
    <b-row>
      <b-col xl="12" lg="12" sm="12">
            <iq-card>
                <template v-slot:body>
                    <b-overlay :show="loading">
                        <b-row>
                            <b-col xl="12" lg="12" sm="12">
                                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                    <b-form @submit.prevent="handleSubmit(storeUpdate)" @reset.prevent="reset" enctype="multipart/form-data">
                                        <b-row>
                                            <b-col xl="6" lg="6" sm="12">
                                                <ValidationProvider name="Type Id" vid="type_id " rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="type_id "
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                        {{$t('irri_pump_main.type_id')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                        plain
                                                        v-model="directroy.type_id"
                                                        id="type_id"
                                                        :options="directoryTypeList"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col xl="6" lg="6" sm="12">
                                                <ValidationProvider name="Name (En)" vid="name" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="name"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('irri_pump_main.namef')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                    id="name"
                                                    v-model="directroy.name"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col xl="6" lg="6" sm="12">
                                                <ValidationProvider name="Name (bn)" vid="name_bn" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="name_bn"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('irri_pump_main.name_bn')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                    id="name_bn"
                                                    v-model="directroy.name_bn"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col xl="6" lg="6" sm="12">
                                                <ValidationProvider name="Division" vid="division_id" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="division_id"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                        {{ $t('org_pro_division.division') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                        plain
                                                        v-model="directroy.division_id"
                                                        :options="divisionList"
                                                        id="division_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col xl="6" lg="6" sm="12">
                                                <ValidationProvider name="District" vid="district_id" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="district"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                        {{ $t('org_pro_district.district') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                        plain
                                                        v-model="directroy.district_id"
                                                        :options="districtList"
                                                        id="district_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col xl="6" lg="6" sm="12">
                                                <ValidationProvider name="Upazila" vid="upazilla_id" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="upazila"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                        {{ $t('org_pro_upazilla.upazilla') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                            plain
                                                            v-model="directroy.upazila_id"
                                                            :options="upazilaList"
                                                            id="upazila_id"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            >
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col xl="6" lg="6" sm="12">
                                                <ValidationProvider name="union" vid="union_id" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="union_id"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                        {{ $t('org_pro_union.union') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                        plain
                                                        v-model="directroy.union_id"
                                                        :options="unionList"
                                                        id="union_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col xl="6" lg="6" sm="12">
                                                <ValidationProvider name="Village Name (En)" vid="village_name" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="village_name"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('irri_pump_main.village_namef')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                    id="village_name"
                                                    v-model="directroy.village_name"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col xl="6" lg="6" sm="12">
                                                <ValidationProvider name="Village Name (Bn)" vid="village_name_bn" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="village_name_bn"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('irri_pump_main.village_name_bn')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                    id="village_name_bn"
                                                    v-model="directroy.village_name_bn"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col xl="6" lg="6" sm="12">
                                                <ValidationProvider name="Address (En)" vid="address" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="address"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('irri_pump_main.addressf')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                    id="address"
                                                    v-model="directroy.address"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col xl="6" lg="6" sm="12">
                                                <ValidationProvider name="Address (Bn)" vid="address_bn" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="address_bn"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('irri_pump_main.address_bn')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                    id="address_bn"
                                                    v-model="directroy.address_bn"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col xl="6" lg="6" sm="12">
                                                <ValidationProvider name="Mobile" vid="mobile" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="mobile"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('irri_pump_main.mobile_no')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                    id="mobile"
                                                    v-model="directroy.mobile"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    onkeyup="this.value=this.value.replace(/[^\d]/,'')"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col xl="6" lg="6" sm="12">
                                                <ValidationProvider name="Email" vid="email">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="email"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('irri_pump_main.email')}}
                                                </template>
                                                <b-form-input
                                                    id="email"
                                                    v-model="directroy.email"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col xl="6" lg="6" sm="12">
                                                <ValidationProvider name="Latitude" vid="latitude">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="latitude"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('irri_pump_main.latitude')}}
                                                </template>
                                                <b-form-input
                                                    id="latitude"
                                                    v-model="directroy.latitude"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col xl="6" lg="6" sm="12">
                                                <ValidationProvider name="Longitude" vid="longitude">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="longitude"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('irri_pump_main.longitude')}}
                                                </template>
                                                <b-form-input
                                                    id="longitude"
                                                    v-model="directroy.longitude"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col xl="6" lg="6" sm="12">
                                                <ValidationProvider name="Document Name (En)" vid="document_name">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="document_name"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('irri_pump_main.document_namef')}}
                                                </template>
                                                <b-form-input
                                                    id="document_name"
                                                    v-model="directroy.document_name"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col xl="6" lg="6" sm="12">
                                                <ValidationProvider name="Document Name (Bn)" vid="document_name_bn">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="document_name_bn"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('irri_pump_main.document_name_bn')}}
                                                </template>
                                                <b-form-input
                                                    id="document_name_bn"
                                                    v-model="directroy.document_name_bn"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col xl="6" lg="6" sm="12">
                                                <ValidationProvider name="Attachment" vid="attachment">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    :label="$t('committee.attachment')"
                                                    label-for="attachment"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <b-form-file
                                                    id="attachment"
                                                    v-on:change="onFileChange"
                                                    v-model="directroy.attachment"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-file>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                        <b-row v-for="(equipment,index) in directroy.pump_directory_equipments" :key="index">
                                            <b-col xl="5" lg="5" sm="8">
                                                <ValidationProvider name="Equipment" vid="master_equipment_type_id" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="master_equipment_type_id"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('irri_pump_main.master_equipment_type_id')}} <span class="text-danger">*</span>
                                                    </template>
                                                        <b-form-select
                                                            plain
                                                            v-model="equipment.master_equipment_type_id"
                                                            :options="equipmentList"
                                                            :id="index"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            >
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col xl="3" lg="3" sm="8">
                                                <ValidationProvider name="Details (En)" vid="details" rules="required">
                                                    <b-form-group
                                                    class="row"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <b-form-input
                                                        :placeholder="$t('irri_pump_main.detailsf')"
                                                        :id="index"
                                                        v-model="equipment.details"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col xl="3" lg="3" sm="8">
                                                <ValidationProvider name="details (Bn)" vid="details_bn" rules="required">
                                                    <b-form-group
                                                    class="row ml-2"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <b-form-input
                                                        :placeholder="$t('irri_pump_main.details_bn')"
                                                        :id="index"
                                                        v-model="equipment.details_bn"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col xl="1" lg="1" sm="4">
                                                <b-button v-show="index == directroy.pump_directory_equipments.length-1" variant=" iq-bg-success" size="sm" @click="add()"><i class="ri-add-line m-0"></i></b-button>
                                                <b-button v-show="index || ( !index && directroy.pump_directory_equipments.length > 1)" variant=" iq-bg-danger" size="sm" @click="remove(index)"><i class="ri-delete-bin-line m-0"></i></b-button>
                                            </b-col>
                                        </b-row>
                                        <div class="row">
                                            <div class="col-sm-3"></div>
                                            <div class="col text-right">
                                                <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                                &nbsp;
                                                <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                                            </div>
                                        </div>
                                    </b-form>
                                </ValidationObserver>
                            </b-col>
                        </b-row>
                    </b-overlay>
                </template>
            </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { directoryStoreApi, directoryUpdateApi } from '../../api/routes'

export default {
    props: ['id'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    created () {
        this.findEquipment()
        if (this.id) {
            const tmp = this.getDirectoryData()
            this.directroy = tmp
            const directoryIdData = tmp.pump_directory_equipments.map(item => {
                return { master_equipment_type_id: item.master_equipment_type_id, details: item.details, details_bn: item.details_bn }
            })
            this.directroy.pump_directory_equipments = directoryIdData
        }
    },
    mounted () {
        core.index()
    },
    data () {
        return {
            loading: false,
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            directroy: {
                type_id: 0,
                name: '',
                name_bn: '',
                division_id: '0',
                district_id: '0',
                upazila_id: '0',
                union_id: '0',
                village_name: '',
                village_name_bn: '',
                document_name: '',
                document_name_bn: '',
                address: '',
                address_bn: '',
                mobile: '',
                email: '',
                latitude: '',
                longitude: '',
                attachment: [],
                pump_directory_equipments: [
                    {
                        master_equipment_type_id: 0,
                        details: '',
                        details_bn: ''
                    }
                ]
            },
            attachmentDemo: '',
            districtList: [],
            upazilaList: [],
            unionList: [],
            equipmentList: []
        }
    },
    computed: {
        divisionList: function () {
            return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
        },
        directoryTypeList: function () {
            const objectData = this.$store.state.commonObj.directoryTypeList
            return objectData.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.id, text: obj.name_bn }
                } else {
                    return { value: obj.id, text: obj.name }
                }
            })
        }
    },
    watch: {
        'directroy.division_id': function (newVal, oldVal) {
        this.districtList = this.getDistrictList(newVal)
        },
        'directroy.district_id': function (newVal, oldVal) {
        this.upazilaList = this.getUpazilaList(newVal)
        },
        'directroy.upazila_id': function (newVal, oldVal) {
        this.unionList = this.getUnionList(newVal)
        }
    },
    methods: {
        getDirectoryData () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            return JSON.parse(JSON.stringify(tmpData))
        },
        onFileChange (e) {
            this.attachmentDemo = e.target.files[0]
        },
        async storeUpdate () {
            this.loading = true
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            try {
                let result = null
                const loadinState = { loading: false, listReload: true }
                const config = {
                    headers: { 'content-type': 'multipart/form-data' }
                }
                var formData = new FormData()
                Object.keys(this.directroy).map(key => {
                    if (key === 'attachment') {
                        formData.append(key, this.attachmentDemo)
                    } else {
                        formData.append(key, JSON.stringify(this.directroy[key]))
                    }
                })

                if (this.directroy.id) {
                    formData.append('_method', 'PUT')
                    result = await RestApi.postData(irriSchemeServiceBaseUrl, `${directoryUpdateApi}/${this.id}`, formData, config)
                } else {
                    result = await RestApi.postData(irriSchemeServiceBaseUrl, directoryStoreApi, formData, config)
                }
                this.$store.dispatch('mutateCommonProperties', loadinState)
                this.loading = false
                if (result.success) {
                    this.$toast.success({
                        title: 'Success',
                        message: this.id ? 'Data updated successfully' : 'Data save successfully',
                        color: '#D6E09B'
                    })
                    this.$bvModal.hide('modal-4')
                } else {
                    this.$refs.form.setErrors(result.errors)
                    this.$toast.error({
                        title: 'Error',
                        message: 'Operation failed! Please, try again.'
                    })
                }
            } catch (error) {
                this.$toast.error({
                title: 'Error',
                message: 'something went wrong! Please, try again.'
                })
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            }
        },
        getDistrictList (divisionId = null) {
            const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
            if (divisionId) {
                return districtList.filter(district => district.division_id === divisionId)
            }
            return districtList
        },
        getUpazilaList (districtId = null) {
            const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
            if (districtId) {
                return upazilaList.filter(upazila => upazila.district_id === districtId)
            }
            return upazilaList
        },
        getUnionList (upazillaId = null) {
            const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
            if (upazillaId) {
                return unionList.filter(union => union.upazilla_id === upazillaId)
            }
            return unionList
        },
        add () {
            const tmpEquipment = {
                master_equipment_type_id: 0,
                details: '',
                details_bn: ''
            }
            const key1 = parseInt(this.directroy.pump_directory_equipments.length - 1)
            const item = this.directroy.pump_directory_equipments[key1]
            let isEmpty = true
            Object.keys(item).map(key => {
                if (item[key] === '' || item[key] === 0) {
                    isEmpty = false
                }
            })
            if (isEmpty === true) {
                this.directroy.pump_directory_equipments.push(tmpEquipment)
            }
        },
        remove (key) {
            this.directroy.pump_directory_equipments.splice(key, 1)
        },
        findEquipment () {
            const activeData = this.$store.state.IrriConfig.commonObj.equipmentTypeList.filter(item => item.status === 0)
            this.equipmentList = activeData.map((obj, index) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text_en }
                }
            })
        }
    }
}

</script>
