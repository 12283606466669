import i18n from '@/i18n'
import Store from '@/store'
// import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
// import { dateFormat } from '@/Utils/fliter'
const exportPdfDetails = async (reportTitle, directoryData, pumpDirectoryEquipments, vm) => {
  try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const pdfContent = [
           ]
        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center' })
        const complainHead = [
          [
            { text: vm.$t('irri_pump_main.type_id'), alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? directoryData.type_name_bn : directoryData.type_name, alignment: 'left' }
          ]
        ]
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['8%', '2%', '15%', '15%', '10%', '30%'],
            body: complainHead
          },
          layout: {
            hLineWidth: function (i, node) {
              return 0
            },
            vLineWidth: function (i, node) {
              return 0
            }
          }
        })
        pdfContent.push({ text: vm.$t('irri_pump_main.personal_details'), style: 'header2', alignment: 'center' })
        const personalDetails = [
          [
            { text: vm.$t('irri_pump_main.name'), style: 'th', alignment: 'left' },
            { text: (i18n.locale === 'bn') ? directoryData.name_bn : directoryData.name, style: 'th', alignment: 'left' },
            { text: vm.$t('org_pro_division.division'), style: 'th', alignment: 'left' },
            { text: (i18n.locale === 'bn') ? directoryData.division_name_bn : directoryData.division_name, style: 'th', alignment: 'left' }
          ],
          [
            { text: vm.$t('org_pro_district.district'), style: 'th', alignment: 'left' },
            { text: (i18n.locale === 'bn') ? directoryData.district_name_bn : directoryData.district_name, style: 'th', alignment: 'left' },
            { text: vm.$t('org_pro_upazilla.upazilla'), style: 'th', alignment: 'left' },
            { text: (i18n.locale === 'bn') ? directoryData.upazila_name_bn : directoryData.upazila_name, style: 'th', alignment: 'left' }
          ],
          [
            { text: vm.$t('org_pro_union.union'), style: 'th', alignment: 'left' },
            { text: (i18n.locale === 'bn') ? directoryData.union_name : directoryData.union_name, style: 'th', alignment: 'left' },
            { text: vm.$t('irri_pump_main.village_name'), style: 'th', alignment: 'left' },
            { text: (i18n.locale === 'bn') ? directoryData.village_name_bn : directoryData.village_name, style: 'th', alignment: 'left' }
          ],
          [
            { text: vm.$t('irri_pump_main.address'), style: 'th', alignment: 'left' },
            { text: (i18n.locale === 'bn') ? directoryData.address_bn : directoryData.address, style: 'th', alignment: 'left' },
            { text: vm.$t('irri_pump_main.email'), style: 'th', alignment: 'left' },
            { text: directoryData.email, style: 'th', alignment: 'left' }
          ],
          [
            { text: vm.$t('irri_pump_main.mobile_no'), style: 'th', alignment: 'left' },
            { text: (i18n.locale === 'bn') ? '০' + vm.$n(directoryData.mobile, { useGrouping: false }) : directoryData.mobile, style: 'th', alignment: 'left' },
            { text: '', style: 'th', alignment: 'left' },
            { text: '', style: 'th', alignment: 'left' }
          ]
        ]
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['25%', '25%', '25%', '25%'],
            body: personalDetails
          }
        })

        pdfContent.push({ text: vm.$t('irri_pump_main.equipment_details'), style: 'header2', alignment: 'center' })

        const detailsData = [
          [
            { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center' },
            { text: vm.$t('irri_pump_main.equipments'), style: 'th', alignment: 'center' },
            { text: vm.$t('irri_pump_main.details'), style: 'th', alignment: 'center' }
          ]
        ]
        pumpDirectoryEquipments.map((item, index) => {
          detailsData.push(
            [
              { text: vm.$n(index + 1), style: 'td', alignment: 'center' },
              { text: (i18n.locale === 'bn') ? vm.$n(item.master_equipment_type_id) : item.master_equipment_type_id, style: 'td', alignment: 'center' },
              { text: (i18n.locale === 'bn') ? vm.$n(item.equipment_name_bn) : item.equipment_name, style: 'td', alignment: 'center' },
              { text: (i18n.locale === 'bn') ? item.details_bn : item.details, style: 'td', alignment: 'center' }
            ]
          )
        })
        pdfContent.push({
          table: {
            headerRows: 2,
            widths: ['20%', '30%', '50%', '100%'],
            body: detailsData
          }
        })

        pdfContent.push({ text: '', style: 'fertilizer', alignment: 'center' })
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'portrait',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
            th: {
              fontSize: 10,
              margin: [3, 3, 3, 3]
            },
            th1: {
                fontSize: 9
              },
            td: {
              fontSize: 8,
              margin: [3, 3, 3, 3]
            },
            search: {
              fontSize: 8,
              margin: [3, 3, 3, 3]
            },
            fertilizer: {
              margin: [0, 10, 0, 7]
            },
            fertilizerSHeader: {
                fontSize: 10,
                margin: [40, 0, 0, 0]
            },
            header: {
              fontSize: 12,
              margin: [0, 0, 0, 4]
            },
            header2: {
              fontSize: 10,
              margin: [0, 10, 0, 20]
            },
            headerPort1: {
              fontSize: 10,
              margin: [0, 20, 0, 5]
            },
            headerPort: {
              fontSize: 10,
              margin: [0, 4, 0, 15]
            },
            krishi: {
              margin: [0, 1, 0, 15],
              alignment: 'center'
            },
            header3: {
              fontSize: 9,
              margin: [0, 15, 0, 0]
            },
            address: {
              fontSize: 9,
              margin: [0, -10, 0, 0]
            },
            tableSubHead: {
              margin: [0, 5, 0, 15]
            }
          }
        }
        pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
