<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row>
                  <b-col lg="12" sm="12">
                    <b-row>
                      <b-col lg="3" sm="12">
                        <p class="text-dark" style="font-weight:bold">{{ $t('irri_pump_main.type_id') }} :</p>
                      </b-col>
                      <b-col lg="9" sm="12">
                         <p class="text-dark">{{ (this.$i18n.locale=='bn') ? directoryData.dir_name_bn : directoryData.dir_name }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="12" sm="12">
                        <div>
                            <b-row>
                                <b-col lg="12" sm="12" class="text-center mb-4">
                                    <h5 class='complain-title'>{{ $t('irri_pump_main.personal_details') }}</h5>
                                </b-col>
                            </b-row>
                          <b-table-simple striped bordered small>
                            <b-tr>
                              <b-th>{{ $t('irri_pump_main.name') }}</b-th>
                              <b-td>{{ (this.$i18n.locale=='bn')? directoryData.name_bn : directoryData.name }}</b-td>
                              <b-th>{{ $t('org_pro_division.division') }}</b-th>
                              <b-td>{{ (this.$i18n.locale=='bn')? directoryData.division_name_bn : directoryData.division_name }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-th>{{ $t('org_pro_district.district') }}</b-th>
                              <b-td>{{ (this.$i18n.locale=='bn')? directoryData.district_name_bn : directoryData.district_name }}</b-td>
                              <b-th>{{ $t('org_pro_upazilla.upazilla') }}</b-th>
                              <b-td>{{ (this.$i18n.locale=='bn')? directoryData.upazila_name_bn : directoryData.upazila_name }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-th>{{ $t('org_pro_union.union') }}</b-th>
                              <b-td>{{ (this.$i18n.locale=='bn')? directoryData.union_name_bn : directoryData.union_name }}</b-td>
                              <b-th>{{ $t('irri_pump_main.village_name') }}</b-th>
                              <b-td>{{ (this.$i18n.locale=='bn')? directoryData.village_name_bn : directoryData.village_name }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-th>{{ $t('irri_pump_main.address') }}</b-th>
                              <b-td>{{ (this.$i18n.locale=='bn')? directoryData.address_bn : directoryData.address }}</b-td>
                              <b-th>{{ $t('irri_pump_main.email') }}</b-th>
                              <b-td>{{ directoryData.email }}</b-td>
                            </b-tr>
                            <b-tr>
                              <b-th>{{ $t('irri_pump_main.mobile_no') }}</b-th>
                              <b-td>{{ $i18n.locale === 'bn' ? '০' : '0' }}{{  $n(directoryData.mobile ,{ useGrouping: false }) }}</b-td>
                            </b-tr>
                          </b-table-simple>
                          <b-row>
                                <b-col lg="12" sm="12" class="text-center mb-4">
                                    <h5 class='complain-title'>{{ $t('irri_pump_main.equipment_details') }}</h5>
                                </b-col>
                          </b-row>
                          <b-table bordered hover :items="directoryData.pump_directory_equipments" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                <template v-slot:cell(index)="data">
                                {{ $n(data.index + 1) }}
                                </template>
                          </b-table>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import ExportPdf from './directoryPDF'
export default {
  name: 'FormLayout',
  props: ['item'],
  created () {
    if (this.item) {
      const tmp = this.getAppInfo()
      Object.freeze(tmp)
      this.directoryData = this.formatList(tmp)
    }
  },
  data () {
    return {
      directoryData: {},
      slOffset: 1
    }
  },
  computed: {
    localeLang () {
      return this.$i18n.locale
    },
    listData () {
      const objectData = this.item
      return objectData.filter(data => data.id === this.item.id && data.pump_directory_id)
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('irri_pump_main.equipments'), class: 'text-left' },
          { label: this.$t('irri_pump_main.details'), class: 'text-left' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'equipment_name_bn' },
          { key: 'details_bn' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'equipment_name' },
          { key: 'details' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    }
  },
  methods: {
    getAppInfo () {
      return this.item
    },
    formatList (data) {
      const directoryList = this.$store.state.commonObj.directoryTypeList
      const divisionList = this.$store.state.commonObj.divisionList
      const districtList = this.$store.state.commonObj.districtList
      const upazilaList = this.$store.state.commonObj.upazilaList
      const unionList = this.$store.state.commonObj.unionList

      const directory = directoryList.find(directory => directory.id === data.type_id)
      const division = divisionList.find(division => division.value === data.division_id)
      const district = districtList.find(district => district.value === data.district_id)
      const upazilla = upazilaList.find(upazila => upazila.value === data.upazila_id)
      const union = unionList.find(union => union.value === data.union_id)
      return Object.assign({}, data,
        { dir_name: directory.name, dir_name_bn: directory.name_bn },
        { division_name: division.text_en, division_name_bn: division.text_bn },
        { district_name: district.text_en, district_name_bn: district.text_bn },
        { upazila_name: upazilla.text_en, upazila_name_bn: upazilla.text_bn },
        { union_name: union.text_en, union_name_bn: union.text_bn }
      )
    },
    pdfExport () {
      const reportTitle = this.$i18n.locale === 'en' ? 'Directory Details' : 'ডিরেক্টরি বিবরণ'
      ExportPdf.exportPdfDetails(reportTitle, this.directoryData, this.directoryData.pump_directory_equipments, this)
    }
   }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
